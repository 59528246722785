/* EditTemplate.css */

/* Container for the editor and full-screen button */
.responsive-editor-container {
    position: relative; /* For positioning the fullscreen button */
    width: 100%;
    height: 100%;
    margin-top: 6px;
    overflow: hidden; /* Prevent overflow */
    display: flex;
    flex-direction: column;
}

.template-title {
    margin-bottom: 4px !important;
    height: min-content;
    font-size: 14px !important;
}

.edit-text-template {
    border: 2px solid gray;
    padding: 12px;
    font-size: 18px;
    width: 100%;
    height: auto;
}

#htmlEditor {
    width: 100% !important;
}

/* Ace Editor Styling */
.ace-editor {
    flex: 1; /* Make the editor take up all available space */
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    line-height: 2 !important; /* Increased line height for readability */
    overflow-y: hidden;
}

.fullscreen-toggle:hover {
    background-color: #2563eb; /* Tailwind's blue-600 */
}

.form-button {
    margin-bottom: 4px !important;
    height: min-content;
}

.ace_scrollbar-h {
    overflow-x: hidden;
    overflow-y: hidden;
    left: 0;
}

.ace_scrollbar {
    contain: strict;
    right: 0;
    bottom: 0;
}

.ace_scrollbar-v {
    overflow-x: hidden;
    overflow-y: hidden;
    top: 0;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .responsive-editor-container {
        height: 400px; /* Adjust height for smaller screens */
    }

    #htmlEditor {
        width: 100vw;
    }

    .preview {
        height: 275px;
    }

}

/* Optional: Enhancing Scrollbars in Preview */
.preview-container {
    scrollbar-width: thin;
    scrollbar-color: #9ca3af transparent; /* Tailwind's gray-400 */
}

.preview-container::-webkit-scrollbar {
    width: 8px;
}

.preview-container::-webkit-scrollbar-track {
    background: transparent;
}

.preview-container::-webkit-scrollbar-thumb {
    background-color: #9ca3af; /* Tailwind's gray-400 */
    border-radius: 4px;
}
