.navbar {
    display: flex;
    gap: 4px;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 0 20px;
}


.logo a {
    color: white;
    font-size: 32px;
    font-weight: bold;
}

.profile-avatar {
    display: flex;
    align-items: center;
}

.ant-dropdown-menu-item {
    padding: 8px 8px !important;
}

.ant-dropdown .ant-dropdown-menu {
    margin-top: 4px;
    box-shadow: none !important;
    border: 1px solid lightgray !important;
}

.profile-avatar .ant-avatar {
    color: black;
    background-color: rgb(249 250 251);
    height: 42px !important;
    width: 42px !important;
}

/* Responsive styling */
@media (max-width: 768px) {
    .logo a {
        font-size: 32px;
    }

    .profile-avatar .ant-avatar {
        width: 32px;
        height: 32px;
        font-size: 18px;
    }
}
