.ant-collapse-header {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between;
}

.mulish-normal {
    font-family: "Mulish", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}